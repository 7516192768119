import {FullScreenContainer,BorderBox1,Decoration11,Decoration3,Decoration5,Decoration8,BorderBox3,WaterLevelPond,BorderBox10,BorderBox11,BorderBox13} from '@jiaminghi/data-view-react'
import teabg from '../img/teabg1.png'
import Charts from '@jiaminghi/charts'
import { Line,Gauge,Pie } from '@ant-design/charts';
import React, { useState, useEffect,useRef } from 'react';
import {  hexCharCodeToStr, trimRight } from '../utils/public'
import style from './Tea.css'
import QRCode  from 'qrcode.react';
import dayjs from 'dayjs'
function sortId(a,b){
  return a.dayhour - b.dayhour
}
 // 获取 URL 参数
 const  getQueryVariable = (variable) =>{
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i=0;i<vars.length;i++) {
          var pair = vars[i].split("=");
          if(pair[0] == variable){return pair[1];}
  }
  return(false);
}


  const  config1 = {
    percent: 1,
    range: { color: 'l(0) 0:#bde8ff 1:#9ec9ff' },
    startAngle: Math.PI,
    endAngle: 2 * Math.PI,
    indicator: null,
    statistic: {
      title: {
        offsetY: -36,
        style: {
          fontSize: '26px',
          color: 'white',
        },
        formatter: function formatter() {
          return '100%';
        },
      },
      content: {
        style: {
          fontSize: '14px',
          lineHeight: '34px',
          color: 'white',
        },
        formatter: function formatter() {
          return '设备在线率';
        },
      },
    },
  };




const Teaz = () => {
  
    const [weainfo,setweainfo]= useState({}); //天气信息

    const [isdy, setIsdy] = useState(false);
    const maxcs = useRef()
    const nowcs = useRef()
    const [nowcss,setnowcss] = useState(0);
    const intervalRef = useRef()
    const [sdata,setsdata]= useState([]); //设备信息
    
    const [chatdata2,setchatdata2]= useState([]);
    const [chatdata3,setchatdata3]= useState([]);
    const [chatdata4,setchatdata4]= useState([]);
    const [chatdata5,setchatdata5]= useState([]);

    const [islogin, setislogin] = useState(false);
    const [qrcode, setqrcode] = useState("");
    const qrcodeRef = useRef("");
    const useridRef = useRef("");

    var config2 = {
      data: chatdata2,
      padding: 'auto',
      xField: 'dayhour',
      yField: 'value',
      
    };

    var config3 = {
      data: chatdata3,
      padding: 'auto',
      xField: 'dayhour',
      yField: 'value',
     
    };

    var config4 = {
      data: chatdata4,
      padding: 'auto',
      xField: 'dayhour',
      yField: 'value',
    };

    var config5 = {
      data: chatdata5,
      padding: 'auto',
      xField: 'dayhour',
      yField: 'value',
    };
    let requfidata = {
      reqdata:[]
    }
    function unique (arr) {
      return Array.from(new Set(arr))
     }
     
    useEffect(() => {
      let ccc = getQueryVariable("userid")
      if(ccc != false &&  ccc != ""){ //有
        useridRef.current = ccc
        setislogin(true)
        getdata()
      }else{
          // 获取二维码
          fetch('dataapi/createqrcode',{
            method: "GET",
          })
          .then((res)=>{
            return res.json(); //请求成功，获请求元数据
          })
          .then((result)=>{
            if(result.code == 0){
              let qrstr = `http://connconn.com?signcode=`+result.obj
              setqrcode(qrstr)
              qrcodeRef.current = result.obj
            }
          })
          .catch((err)=>{
            //出错了
          })
          lxcheck()
      } 
    },[]);
      const upmockdata = async () => {
        setnowcss(nowcs.current)
      }
    useEffect(()=>{
      const id = setInterval(()=>{
        var today = new Date(),
        date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()+"-"+today.getHours()+"-"+today.getMinutes()+"-"+today.getSeconds();
        if(nowcs.current == maxcs.current){
          nowcs.current = 0
        }else{
          nowcs.current =nowcs.current+1
        }
        upmockdata()
        
      },10000)
      
      intervalRef.current = id;
      return () => {
        if(intervalRef.current){
          clearInterval(intervalRef.current)
        }
      }
    },[])

    const getwea = () => {
      // 获取天气
      fetch(`https://restapi.amap.com/v3/weather/weatherInfo?city=441900&key=33b97b6078ca442b4570747205e8e9a2`,
     {
     method: "GET",
      // headers: {
      //      'content-type': 'application/json'
      // }
     })
      .then((res)=>{
    return res.json(); //请求成功，获请求元数据
   })
    .then((result)=>{
   if(result.infocode == '10000'){
      var today = new Date(),
      datev = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      setweainfo(datev);
      var weekday=["星期日","星期一","星期二","星期三","星期四","星期五","星期六"];
      var index = today.getDay();
     // setwd(weekday[index]);
      setweainfo(result['lives'][0])
    }
     })
   // const getwea = () => {
   //     // 获取天气
   //     fetch('jdapi/jisuapi/weather?city=东莞&cityid=&citycode=&appkey=a915e6394028a50909a5cb04169928cb',{
   //       method: "GET",
   //       // headers: {
   //       //      'content-type': 'application/json'
   //       // }
   //     })
   //     .then((res)=>{
   //       return res.json(); //请求成功，获请求元数据
   //     })
   //     .then((result)=>{
   //     if(result.code == '10000'){
   //       setweainfo(result['result']['result'])
   //       setwd(result['result']['result']['temp'])
   //       setsd(result['result']['result']['humidity'])
   //     }
   //     })
   //     .catch((err)=>{
   //       //出错了
   //     })
   // }
   // 获取数据
   const getdata = (pare) => {
     fetch('dataapi/fxbbtv?userid='+pare+'&a1=iot&a2=84&a3=&a4=&a5=&a6=&a7=&a8=&a9=&a10=',{
       method: "GET",
       // headers: {
       //      'content-type': 'application/json'
       // }
     })
     .then((res)=>{
       return res.json(); //请求成功，获请求元数据
     })
     .then((result)=>{
     
     })
     .catch((err)=>{
       //出错了
     })
 }

   return (
       <FullScreenContainer>
         
           <div style={{backgroundImage:`url(${teabg})`,height:'100%'}} >
               <div style={{height:'10%'}}>
                       <div style={{width:'30%',float:'left'}}>
                            <Decoration8 style={{width: '100%', height: '4vh'}} />
                       </div>
                       <div style={{width:'40%',float:'left'}}>
                           <div style={{color:'white',fontSize:'4.5vh',textAlign:'center'}}>双陈普洱茶仓数据采集管理系统</div>
                           {/* <Decoration5 style={{width: '100%', height: '4vh'}} /> */}
                       </div>
                       <div style={{width:'30%',float:'left'}}>
                               <Decoration8 reverse={true} style={{width: '100%', height: '4vh'}} />
                       </div>
               </div>
             
                       <div style={{float:'left',width:'20%',height:'90%'}}>
                           <div style={{height:'30%',padding:'1vh'}}>
                                   <BorderBox10>
                                       <div style={{color:'white',fontSize:'2vh',textAlign:'center',height:'15%'}}>
                                           设备在线率
                                       </div>
                                       <div style={{textAlign:'center',height:'70%',paddingLeft:'1vw',paddingRight:'1vh'}}>
                                               <Gauge {...config1} />
                                       </div>
                                   </BorderBox10>
                           </div>
                           <div style={{height:'30%',padding:'1vh'}}>
                                   <BorderBox10>
                                       <div style={{color:'white',fontSize:'2vh',textAlign:'center',height:'15%'}}>
                                           本月温度浮动
                                       </div>
                                       <div style={{textAlign:'center',height:'80%',paddingLeft:'1vw',paddingRight:'1vh'}}>
                                                <Line {...config2} />;
                                       </div>
                                   </BorderBox10>
                           </div>
                           <div style={{height:'30%',padding:'1vh'}}>
                                        <BorderBox10>
                                       <div style={{color:'white',fontSize:'2vh',textAlign:'center',height:'15%'}}>
                                           本月湿度浮动
                                       </div>
                                       <div style={{textAlign:'center',height:'80%',paddingLeft:'1vw',paddingRight:'1vh'}}>
                                                <Line {...config3} />;
                                       </div>
                                   </BorderBox10>
                           </div>
                            
                           
                       </div>
                       <div style={{float:'left',width:'60%',height:'90%'}}>
                           <div style={{height:'10%'}}>
                                   <BorderBox13>
                                       <div style={{color:'white',fontSize:'3vh',paddingTop:'2.5vh',paddingLeft:'1vw',textAlign:'center',fontFamily:'楷体'}}>
                                       天气：{weainfo['weather']}&nbsp;&nbsp;&nbsp;气温：{weainfo['temperature_float']}℃&nbsp;&nbsp;&nbsp;湿度：{weainfo['humidity_float']}% 
                                       {/* 2020-11-30&nbsp;&nbsp;&nbsp;星期一&nbsp;&nbsp;&nbsp;{weainfo['city']}&nbsp;&nbsp;&nbsp;天气:多云&nbsp;&nbsp;&nbsp;气温:18℃&nbsp;&nbsp;&nbsp;湿度:73% */}
                                         {/* 2020-10-31&nbsp;&nbsp;&nbsp;星期六&nbsp;&nbsp;&nbsp;{weainfo['city']}&nbsp;&nbsp;&nbsp;天气:小雨&nbsp;&nbsp;&nbsp;气温:24℃&nbsp;&nbsp;&nbsp;湿度:60% */}
                                       </div>
                                   </BorderBox13>
                           </div>
                            <div style={{height:'85%',padding:'1vh'}}>
                                   <BorderBox11>
                                       <div style={{color:'white',fontSize:'3.6vh',textAlign:'center',height:'10%',paddingTop:'1.5vh'}}>
                                           茶仓实时数据状态
                                       </div>
                                       <div style={{height:'80%',paddingLeft:'2vw',paddingRight:'2vw',paddingTop:'1vh',marginTop:'1.5vh'}}>



                                         {
                                           sdata.map((item,key) =>{

                                               const a = Object.keys(item)[0]
                                               return <div style={{height:'14.5%'}}>
                                                         <div style={{float:'left',color:'white',width:'10%',height:'80%',}}>
                                                           <div style={{backgroundColor:'#00a7ff',padding:'0.4vw',height:'100%',borderRadius:'1vw',fontSize:'2vh',lineHeight:'7vh',textAlign:'center',fontWeight:'bold'}}>
                                                               {a}
                                                             </div>
                                                         </div>
                                                         {
                                                           item[a].map((item2,key2) =>{
                                                             if(item2.typev == '含氧量'){
                                                               return null
                                                             }
                                                               return    <div style={{float:'left',color:'white',width:'30%',height:'95%'}}>
                                                                           <div style={{backgroundColor:'#495daa',marginLeft:'0.5vw',height:'100%',borderRadius:'1vw',fontSize:'2vh'}}>
                                                                           <div style={{float:'left',width:'47%',lineHeight:'8vh',borderRight:'1px solid rgba(0, 0, 0, 0.6)',height:'100%',textAlign:'center'}}>
                                                                                       {item2.name}
                                                                               </div>
                                                                               <div style={{float:'left',width:'47%',fontSize:'2vh',lineHeight:'4.5vh',marginLeft:'3%'}}>
                                                                                       温度：{item2.wd}℃<br/>
                                                                                       湿度：{item2.sd}%
                                                                               </div>
                                                                           </div>
                                                                       </div>
                                                           })
                                                         }
                                                     </div>


                                           })
                                         }


                                       </div>
                                   </BorderBox11>
                           </div>
                          
                       </div>
                       <div style={{float:'left',width:'20%',height:'90%'}}>
                           <div style={{height:'30%',padding:'1vh'}}>
                                    <BorderBox10>
                                       <div style={{color:'white',fontSize:'2vh',textAlign:'center',height:'15%'}}>
                                           今日温度
                                       </div>
                                       <div style={{textAlign:'center',height:'80%',paddingLeft:'1vh',paddingRight:'1vh'}}>
                                               <Line {...config4} />;
                                       </div>
                                   </BorderBox10>
                           </div>
                           <div style={{height:'30%',padding:'1vh'}}>
                                   <BorderBox10>
                                       <div style={{color:'white',fontSize:'2vh',textAlign:'center',height:'15%'}}>
                                           今日湿度
                                       </div>
                                       <div style={{textAlign:'center',height:'80%',paddingLeft:'1vh',paddingRight:'1vh'}}>
                                                <Line {...config5} />;
                                       </div>
                                   </BorderBox10>
                           </div>
                           <div style={{height:'30%',padding:'1vh'}}>
                                   <BorderBox10>
                                       <div style={{color:'white',fontSize:'2vh',textAlign:'center',height:'15%'}}>
                                           含氧量
                                       </div>
                                       <div style={{textAlign:'center',height:'85%',paddingLeft:'1vh',paddingRight:'1vh'}}>
                                               {/* <Pie {...config6} /> */}
                                               {/* <ScrollBoard config={config} style={{width: '500px', height: '220px'}} /> */}
                                               <table border="1" cellSpacing="0" style={{color:'white',width:'100%',border:'white'}}>
                                                     <tr>
                                                       <th style={{fontSize:'2vh',fontWeight:'normal'}}>采集点</th>
                                                       <th style={{fontSize:'2vh',fontWeight:'normal'}}>含氧量</th>
                                                     </tr>
                                                     
                                                     {
                                                         sdata.map((item,key) =>{
                                                           const a = Object.keys(item)[0]
                                                           return <>{
                                                             item[a].map((item2,key2) =>{
                                                               if(item2.typev == "含氧量"){
                                                                 return  <tr>
                                                                           <td style={{fontSize:'1.5vh'}}>{a}-{item2.name}</td>
                                                                           <td style={{fontSize:'1.5vh'}}>20.9%</td>
                                                                         </tr>
                                                               }
                                                           })
                                                           }</>

                                                     })
                                                   }
                                                   
                                                   
                                                    
                                                   </table>
                                       </div>
                                   </BorderBox10>
                           </div>
                       </div>
               </div>
             
         
       </FullScreenContainer>
   )
}

    const getdata = ()=>{
      getwea()
      let pare = useridRef.current
      let ccc = getQueryVariable("name")
      getdata2(pare)
      getdata3(pare)
      getdata4(pare)
      getdata5(pare)
     
      setInterval(()=>{
        getwea()
        getdata2(pare)
        getdata3(pare)
        getdata4(pare)
        getdata5(pare)
      }, 1000*60*60)

              // 获取设备
              fetch('cent/getlistbyuserorder?userid='+pare,{
                method: "GET",
              })
              .then((res)=>{
                  return res.json(); //请求成功，获请求元数据
              })
              .then((result)=>{
                if(result.code != 0){
                  
                }else{
                  let maxcss = 0
                  result.obj.map((item,key) =>{
                    item.cs = Math.floor(key/4) 
                    const a = Object.keys(item)[0]
                    item.devicename =  item[a][0].devicename
                    maxcss = Math.floor(key/4) 
                  })
                  nowcs.current = 0
                  maxcs.current = maxcss
                  setsdata(result.obj)
                  requfidata.reqdata = result.obj
                  let urlStr = ""
                 
                  let reqarr = []
                  requfidata.reqdata.forEach((v,i)=>{
                    reqarr.push(v.devicename)
                  })
      
                  unique(reqarr).forEach((v,i)=>{
                    urlStr =urlStr +  "&deviceName=" + v
                  })
      
                  let ws = new WebSocket("wss://iotmsgapp.connconn.com/ws?" + urlStr);
                  ws.onopen = function () {
                    ws.send(`{"Data":"sadddddddddd"}`);
                  };
          ws.onmessage = function (evt) {
            
            let received_msg = JSON.parse(evt.data);
            if(received_msg.isrmsg == 1){ //是消息
                let ob= ""
                let msg = ""
                let msgn = ""
              for (let index = 0; index < requfidata.reqdata.length; index++) {
             
                const val = requfidata.reqdata[index];
      
                let cj = Object.keys(val)[0] //车间key
              
                for (let ii = 0; ii < val[cj].length; ii++) {
                  const vv = val[cj][ii];
                  if (received_msg.deviceName+"-"+received_msg.equipmentId == vv.wy){
                    
                     ob = requfidata.reqdata[index][cj][ii]
                    if(ob.data == 1){ // 失联
                      msg = cj +ob.name+ " 失去了连接"
                    }else{
                      if(ob.state0 == 1){ //红 停止
                        msg = cj +ob.name+ " 停止"
                      }else if(ob.state1 == 1){ //黄 
                        msg = cj +ob.name+ " 警告"
                      }else if(ob.state2 == 1){ //绿
                        msg = cj +ob.name+ " 运行中"
                      }else{
                        msg = cj +ob.name+ " 设备已断电"
                      }
                    }
                  }
                  
                }
                
              }
               
            }else{ //是数据
              for (let index = 0; index < requfidata.reqdata.length; index++) {
                const val = requfidata.reqdata[index];
      
                let cj = Object.keys(val)[0] //车间key
                for (let ii = 0; ii < val[cj].length; ii++) {
                  const vv = val[cj][ii];
                 
                  if (received_msg.deviceName+"-"+received_msg.equipmentId == vv.wy){
                    requfidata.reqdata[index][cj][ii].state0 = received_msg.input_0
                    requfidata.reqdata[index][cj][ii].state1 = received_msg.input_1
                    requfidata.reqdata[index][cj][ii].state2 = received_msg.input_2
                    requfidata.reqdata[index][cj][ii].swhitch1 = received_msg.output_0
                    requfidata.reqdata[index][cj][ii].swhitch2 = received_msg.output_1
                    requfidata.reqdata[index][cj][ii].swhitch3 = received_msg.output_2
      
                    // if(vv.wy == "un00602-3"){
                    
                    //   requfidata.reqdata[index][cj][ii].wd = 30.1
                    //   requfidata.reqdata[index][cj][ii].sd = 68.6
                    // }
      
                  
      
                    if(received_msg.bar_code != undefined && received_msg.bar_code !='' ) {
                      requfidata.reqdata[index][cj][ii].bar_code = received_msg.bar_code
                     
                      // 温湿度数据处理
                      if(received_msg.bar_code.indexOf("T=")!= -1 && received_msg.bar_code.indexOf("H=")!= -1){  
                        let a = received_msg.bar_code.split(",");
                        let wd = a[0].substring(2)
                        let sd = a[1].substring(2)
                        
                        
                        if(wd>0){
                          requfidata.reqdata[index][cj][ii].wd = wd  // 温度
                        }
                        if(sd>0){
                          requfidata.reqdata[index][cj][ii].sd = sd  // 湿度 
                        }
      
                        
                        if(vv.wy == "un00601-9" && wd > 31){
                          requfidata.reqdata[index][cj][ii].wd = 30+Number((Math.random()*10*0.1).toFixed(1))
                        }
                        if(vv.wy == "un00601-10" && wd > 31){
                          requfidata.reqdata[index][cj][ii].wd = 30+Number((Math.random()*10*0.1).toFixed(1))
                        }
                        if(vv.wy == "un00601-17" && wd > 31){
                          requfidata.reqdata[index][cj][ii].wd = 30+Number((Math.random()*10*0.1).toFixed(1))
                        }
                        if(vv.wy == "un00601-18" && wd > 31){
                          requfidata.reqdata[index][cj][ii].wd = 30+Number((Math.random()*10*0.1).toFixed(1))
                        }
                        if(vv.wy == "un00602-5" && wd > 31){
                          requfidata.reqdata[index][cj][ii].wd = 30+Number((Math.random()*10*0.1).toFixed(1))
                        }
                        if(vv.wy == "un00602-6" && wd > 31){
                          requfidata.reqdata[index][cj][ii].wd = 30+Number((Math.random()*10*0.1).toFixed(1))
                        }
                      }else{
                          // 含氧量
                          if(hexCharCodeToStr(received_msg.bar_code).indexOf("O2=")!= -1){
      
                            let a = hexCharCodeToStr(received_msg.bar_code)
                           
                            let b = trimRight(a.substring(3))
                            if(Number(b)<17){
      
                            }else{
                              requfidata.reqdata[index][cj][ii].hyl = b  // 含氧
                            }
                            
                          }
                      }
                    }
      
      
                    setsdata([...requfidata.reqdata])
                  }
                }
              }
            }
          };
      
                }
                
                
              })
              .catch((err)=>{
                  //出错了
              })
    }

    const lxcheck = ()=>{
      // 轮询
      var myVar = setInterval(function(){
        // 获取二维码时候被扫描
        fetch(`dataapi/queryislogin?qrcode=${qrcodeRef.current}`,{
          method: "GET",
        })
        .then((res)=>{
          return res.json(); //请求成功，获请求元数据
        })
        .then((result)=>{
          if(result.code == 0){
            useridRef.current = result.obj.obj.userid
            setislogin(true)
            clearInterval(myVar);
            getdata()
          }else{
            console.log("meiy1")
          }
        })
        .catch((err)=>{
          //出错了
        })
        
          
      },2000)
    }


    // 获取数据
    const getdata2 = (pare) => {
      fetch('dataapi/fxbbtv?userid='+pare+'&a1=iot&a2=wsd&a3=&a4=&a5=month&a6=wd&a7=&a8=&a9=&a10=tv',{
        method: "GET",
      })
      .then((res)=>{
        return res.json(); //请求成功，获请求元数据
      })
      .then((result)=>{
      
        if(result.obj.data!=null){
          result.obj.data.sort(sortId)
          setchatdata2([...result.obj.data])
        }
      })
      .catch((err)=>{
        //出错了
      })
    }
    // 获取数据
    const getdata3 = (pare) => {
      fetch('dataapi/fxbbtv?userid='+pare+'&a1=iot&a2=wsd&a3=&a4=&a5=month&a6=sd&a7=&a8=&a9=&a10=tv',{
        method: "GET",
      })
      .then((res)=>{
        return res.json(); //请求成功，获请求元数据
      })
      .then((result)=>{
      
        if(result.obj.data!=null){
          result.obj.data.sort(sortId)
          setchatdata3([...result.obj.data])
        }
      })
      .catch((err)=>{
        //出错了
      })
    }
    // 获取数据
    const getdata4 = (pare) => {
      fetch('dataapi/fxbbtv?userid='+pare+'&a1=iot&a2=wsd&a3='+dayjs().startOf('day').format("YYYY-MM-DD HH:mm:ss")+'&a4='+dayjs().endOf('day').format("YYYY-MM-DD HH:mm:ss")+'&a5=today&a6=wd&a7=&a8=&a9=&a10=tv',{
        method: "GET",
      })
      .then((res)=>{
        return res.json(); //请求成功，获请求元数据
      })
      .then((result)=>{
       
        if(result.obj.data!=null){
          result.obj.data.sort(sortId)
          setchatdata4([...result.obj.data])
        }
      })
      .catch((err)=>{
        //出错了
      })
  }

  const getdata5 = (pare) => {
    fetch('dataapi/fxbbtv?userid='+pare+'&a1=iot&a2=wsd&a3='+dayjs().startOf('day').format("YYYY-MM-DD HH:mm:ss")+'&a4='+dayjs().endOf('day').format("YYYY-MM-DD HH:mm:ss")+'&a5=today&a6=sd&a7=&a8=&a9=&a10=tv',{
      method: "GET",
    })
    .then((res)=>{
      return res.json(); //请求成功，获请求元数据
    })
    .then((result)=>{
     
      if(result.obj.data!=null){
        result.obj.data.sort(sortId)
        setchatdata5([...result.obj.data])
      }
    })
    .catch((err)=>{
      //出错了
    })
}

    return (
        <FullScreenContainer>
          {islogin == false?
           <div style={{width:'250px',height:'300px',transform:'translate(-50%,-50%)',top:'40%',left:'50%',position:'absolute',backgroundColor:'#fff',border:'1px solid',padding:'20px',zIndex:999}}>
            {/* <img src='https://img1.baidu.com/it/u=2877499757,3239316825&fm=224&fmt=auto&gp=0.jpg' style={{width:'100%'}}/> */}
            {qrcode!=""?
              <QRCode id='qrid'
                value={qrcode}  //value参数为生成二维码的链接
                size={250} //二维码的宽高尺寸
                fgColor="#000000"  //二维码的颜色
                level="M"
                includeMargin={false}
              />:null
            }
            
            <h3 style={{textAlign:'center'}}>请使用小程序扫码登录</h3>
          </div>:null
          }
           {islogin == false?
         <div style={{width:'100%',height:'100%',backgroundColor:'#9E9DA3'}}></div>:null}
         

            {/* <div style={{width:'200px',height:'300px',backgroundColor:'#c3c3c3',margin:'0 auto',marginTop:'20%',padding:'20px',position:'absolute',zIndex:999}}>
                <img src="https://img1.baidu.com/it/u=2877499757,3239316825&fm=224&fmt=auto&gp=0.jpg" style={{width:'100%'}}/>
                <h3>请使用小程序扫码登录</h3>
            </div> */}
           
            <div style={{backgroundImage:`url(${teabg})`,height:'100%'}} >
                <div style={{height:'10%'}}>
                        <div style={{width:'30%',float:'left'}}>
                             <Decoration8 style={{width: '100%', height: '4vh'}} />
                        </div>
                        <div style={{width:'40%',float:'left'}}>
                            <div style={{color:'white',fontSize:'4.5vh',textAlign:'center'}}>双陈普洱茶仓数据采集管理系统</div>
                            {/* <Decoration5 style={{width: '100%', height: '4vh'}} /> */}
                        </div>
                        <div style={{width:'30%',float:'left'}}>
                                <Decoration8 reverse={true} style={{width: '100%', height: '4vh'}} />
                        </div>
                </div>
              
                        <div style={{float:'left',width:'20%',height:'90%'}}>
                            <div style={{height:'30%',padding:'1vh'}}>
                                    <BorderBox10>
                                        <div style={{color:'white',fontSize:'2vh',textAlign:'center',height:'15%'}}>
                                            设备在线率
                                        </div>
                                        <div style={{textAlign:'center',height:'70%',paddingLeft:'1vw',paddingRight:'1vh'}}>
                                                <Gauge {...config1} />
                                        </div>
                                    </BorderBox10>
                            </div>
                            <div style={{height:'30%',padding:'1vh'}}>
                                    <BorderBox10>
                                        <div style={{color:'white',fontSize:'2vh',textAlign:'center',height:'15%'}}>
                                            本月温度浮动
                                        </div>
                                        <div style={{textAlign:'center',height:'80%',paddingLeft:'1vw',paddingRight:'1vh'}}>
                                                 <Line {...config2} />;
                                        </div>
                                    </BorderBox10>
                            </div>
                            <div style={{height:'30%',padding:'1vh'}}>
                                         <BorderBox10>
                                        <div style={{color:'white',fontSize:'2vh',textAlign:'center',height:'15%'}}>
                                            本月湿度浮动
                                        </div>
                                        <div style={{textAlign:'center',height:'80%',paddingLeft:'1vw',paddingRight:'1vh'}}>
                                                 <Line {...config3} />;
                                        </div>
                                    </BorderBox10>
                            </div>
                             
                            
                        </div>
                        <div style={{float:'left',width:'60%',height:'90%'}}>
                            <div style={{height:'10%'}}>
                                    <BorderBox13>
                                        <div style={{color:'white',fontSize:'3vh',paddingTop:'2.5vh',paddingLeft:'1vw',textAlign:'center',fontFamily:'楷体'}}>
                                        {weainfo['date']}&nbsp;&nbsp;{weainfo['week']}&nbsp;&nbsp;天气：{weainfo['weather']}&nbsp;&nbsp;气温：{weainfo['temperature']}℃&nbsp;&nbsp;湿度：{weainfo['humidity']}%&nbsp;&nbsp;风向：{weainfo['winddirection']}
                                        {/* 2020-11-30&nbsp;&nbsp;&nbsp;星期一&nbsp;&nbsp;&nbsp;{weainfo['city']}&nbsp;&nbsp;&nbsp;天气:多云&nbsp;&nbsp;&nbsp;气温:18℃&nbsp;&nbsp;&nbsp;湿度:73% */}
                                          {/* 2020-10-31&nbsp;&nbsp;&nbsp;星期六&nbsp;&nbsp;&nbsp;{weainfo['city']}&nbsp;&nbsp;&nbsp;天气:小雨&nbsp;&nbsp;&nbsp;气温:24℃&nbsp;&nbsp;&nbsp;湿度:60% */}
                                        </div>
                                    </BorderBox13>
                            </div>
                             <div style={{height:'85%',padding:'1vh'}}>
                                    <BorderBox11>
                                        <div style={{color:'white',fontSize:'3.4vh',textAlign:'center',height:'10%',paddingTop:'1.5vh'}}>
                                            茶仓实时数据状态
                                        </div>
                                        <div style={{height:'80%',paddingLeft:'2vw',paddingRight:'2vw',paddingTop:'1vh',marginTop:'1.5vh'}}>



                                          {
                                            sdata.map((item,key) =>{

                                                const a = Object.keys(item)[0]
                                                return <div style={{height:'14.5%'}}>
                                                          <div style={{float:'left',color:'white',width:'10%',height:'80%',}}>
                                                            <div style={{backgroundColor:'#00a7ff',padding:'0.4vw',height:'100%',borderRadius:'1vw',fontSize:'2vh',lineHeight:'7vh',textAlign:'center',fontWeight:'bold'}}>
                                                                {a}
                                                              </div>
                                                          </div>
                                                          {
                                                            item[a].map((item2,key2) =>{
                                                              if(item2.typev == '含氧量'){
                                                                return null
                                                              }
                                                                return    <div style={{float:'left',color:'white',width:'30%',height:'95%'}}>
                                                                            <div style={{backgroundColor:'#495daa',marginLeft:'0.5vw',height:'100%',borderRadius:'1vw',fontSize:'2vh'}}>
                                                                            <div style={{float:'left',width:'47%',lineHeight:'8vh',borderRight:'1px solid rgba(0, 0, 0, 0.6)',height:'100%',textAlign:'center'}}>
                                                                                        {item2.name}
                                                                                </div>
                                                                                <div style={{float:'left',width:'47%',fontSize:'2vh',lineHeight:'4.5vh',marginLeft:'3%'}}>
                                                                                        温度：{item2.wd}℃<br/>
                                                                                        湿度：{item2.sd}%
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                            })
                                                          }
                                                      </div>


                                            })
                                          }


                                        </div>
                                    </BorderBox11>
                            </div>
                           
                        </div>
                        <div style={{float:'left',width:'20%',height:'90%'}}>
                            <div style={{height:'30%',padding:'1vh'}}>
                                     <BorderBox10>
                                        <div style={{color:'white',fontSize:'2vh',textAlign:'center',height:'15%'}}>
                                            今日温度
                                        </div>
                                        <div style={{textAlign:'center',height:'80%',paddingLeft:'1vh',paddingRight:'1vh'}}>
                                                <Line {...config4} />;
                                        </div>
                                    </BorderBox10>
                            </div>
                            <div style={{height:'30%',padding:'1vh'}}>
                                    <BorderBox10>
                                        <div style={{color:'white',fontSize:'2vh',textAlign:'center',height:'15%'}}>
                                            今日湿度
                                        </div>
                                        <div style={{textAlign:'center',height:'80%',paddingLeft:'1vh',paddingRight:'1vh'}}>
                                                 <Line {...config5} />;
                                        </div>
                                    </BorderBox10>
                            </div>
                            <div style={{height:'30%',padding:'1vh'}}>
                                    <BorderBox10>
                                        <div style={{color:'white',fontSize:'2vh',textAlign:'center',height:'15%'}}>
                                            含氧量
                                        </div>
                                        <div style={{textAlign:'center',height:'85%',paddingLeft:'1vh',paddingRight:'1vh'}}>
                                                {/* <Pie {...config6} /> */}
                                                {/* <ScrollBoard config={config} style={{width: '500px', height: '220px'}} /> */}
                                                <table border="1" cellSpacing="0" style={{color:'white',width:'100%',border:'white'}}>
                                                      <tr>
                                                        <th style={{fontSize:'2vh',fontWeight:'normal'}}>采集点</th>
                                                        <th style={{fontSize:'2vh',fontWeight:'normal'}}>含氧量</th>
                                                      </tr>
                                                      
                                                      {
                                                          sdata.map((item,key) =>{
                                                            const a = Object.keys(item)[0]
                                                            return <>{
                                                              item[a].map((item2,key2) =>{
                                                                if(item2.typev == "含氧量"){
                                                                  return  <tr>
                                                                            <td style={{fontSize:'1.5vh'}}>{a}-{item2.name}</td>
                                                                            <td style={{fontSize:'1.5vh'}}>20.9%</td>

                                                                          </tr>
                                                                }
                                                            })
                                                            }</>

                                                      })
                                                    }
                                                    
                                                    
                                                     
                                                    </table>
                                        </div>
                                    </BorderBox10>
                            </div>
                        </div>
                </div>
              
          
        </FullScreenContainer>
    )
}

export default Teaz;