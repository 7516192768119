import logo from './logo.svg';
import './App.css';
import Tea from './SCYP/Tea'
import Teaj from './SCYP/Teaj'
import Qdtea from './QDSCYP/Tea'
import Fgsctea from './SCYP/Fgsctea'
import Ytea from './SCYP/Ytea'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
function App() {
  return (
    <Router>
      <div>
       
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          
          {/* 茶叶 真数据 */}
          <Route path="/tea">  
            <Tea />
          </Route>
          {/* 亿态 真数据 */}
          <Route path="/ytea">  
            <Ytea />
          </Route>
          {/* 风岗双陈 真数据 */}
          <Route path="/fgsctea">  
            <Fgsctea />
          </Route>
          <Route path="/qdtea">  
            <Qdtea />
          </Route>
          
          <Route path="/teaj">  
            <Teaj />
          </Route>

         
         
          <Route path="/">
            <>轻物联云</>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
