// 十六进制转字符串
export const hexCharCodeToStr = (hexCharCodeStr)=> {
    　　var trimedStr = hexCharCodeStr.trim();
    　　var rawStr = 
    　　trimedStr.substr(0,2).toLowerCase() === "0x"
    　　? 
    　　trimedStr.substr(2) 
    　　: 
    　　trimedStr;
    　　var len = rawStr.length;
    　　if(len % 2 !== 0) {
    　　　　alert("Illegal Format ASCII Code!");
    　　　　return "";
    　　}
    　　var curCharCode;
    　　var resultStr = [];
    　　for(var i = 0; i < len;i = i + 2) {
    　　　　curCharCode = parseInt(rawStr.substr(i, 2), 16); // ASCII Code Value
    　　　　resultStr.push(String.fromCharCode(curCharCode));
    　　}
    　　return resultStr.join("");
    }
    
    export const trimRight = (s) =>{  
        if(s == null) return "";  
        var whitespace = new String(" \t\n\r");  
        var str = new String(s);  
        if (whitespace.indexOf(str.charAt(str.length-1)) != -1){  
            var i = str.length - 1;  
            while (i >= 0 && whitespace.indexOf(str.charAt(i)) != -1){  
               i--;  
            }  
            str = str.substring(0, i+1);  
        }  
        return str;  
    }  
    